import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import ActiveTabMixin from './ActiveTabMixin'
import FormBase from './FormBase'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import PasswordInput from '@/components/form/Inputs/PasswordInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTab from '@/components/form/FormTab'
import FormTabs from '@/components/form/FormTabs'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import ReadOnlyText from '@/components/form/Inputs/ReadOnlyText'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import TagInput from '@/components/form/Inputs/TagInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import TransTextareaInput from '@/components/form/Inputs/TransTextareaInput'
import TransTextInput from '@/components/form/Inputs/TransTextInput'
import ImageUploadInput from '@/components/form/Inputs/ImageUploadInput'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        FormThreeColRow,
        TextInput,
        PasswordInput,
        CheckboxInput,
        EntitySelectInput,
        SelectInput,
        DatePickerInput,
        FormOneColRow,
        FormTab,
        FormTabs,
        AddressMapInput,
        AddressAutocompleteInput,
        ReadOnlyText,
        TextareaInput,
        TagInput,
        CurrencyInput,
        TransTextareaInput,
        TransTextInput,
        ImageUploadInput,
    },
    mixins: [FormBase, ActiveTabMixin],
    directives: {
        tabError(el, binding, vNode) {
            if (!vNode.componentOptions.propsData.name) {
                console.warn('FormExtend, missing name', el)

                return
            }

            if (!vNode.componentOptions.propsData.errorMessages) {
                console.warn(
                    'FormExtend, missing errorMessages for ' +
                        vNode.componentOptions.propsData.name
                )

                return
            }

            const inputName = vNode.componentOptions.propsData.name
            const errorCount =
                vNode.componentOptions.propsData.errorMessages.length
            const tabName = binding.arg

            const errorsClone = cloneDeep(vNode.context.tabErrorsContainer)

            if (!errorsClone[tabName]) {
                errorsClone[tabName] = {}
            }

            errorsClone[tabName][inputName] = errorCount

            if (!isEqual(errorsClone, vNode.context.tabErrorsContainer)) {
                vNode.context.tabErrorsContainer = errorsClone
            }
        },
    },
}
